<template>
    <div class="stu-info-fill">
        <template v-if="mode == 'view'">
            <div
                v-if="selectedTemplate > -1"
                class="form-div"
            >
                <StuInfoFillMain
                    :mode="mode"
                    :school-id="schoolId"
                    :stu-basic-info="stuBasicInfo"
                    :template-info="templateList"
                />
            </div>
        </template>
        <template v-else>
            <div
                v-if="selectedTemplate > -1"
                class="form-div"
            >
            <!--
                v-for="(item, index) in templateList"
                    :key="index"
                    :mode="item.mode"
                    :school-id="schoolId"
                    :stu-basic-info="stuBasicInfo"
                    :template-info="item"
             -->
                <stu-info-fill-main
                    :key="selectedTemplate"
                    :mode="templateList[selectedTemplate].mode"
                    :school-id="schoolId"
                    :stu-basic-info="stuBasicInfo"
                    :template-info="templateList[selectedTemplate]"
                ></stu-info-fill-main>
            </div>
        </template>
    </div>
</template>

<script>
import StuInfoFillMain from "./FillMain.vue";

export default {
    name: "StuInfoFill",
    components: {
        StuInfoFillMain,
    },
    props: [
        "type",
        "mode",
        "schoolId",
        "stuBasicInfo",
        'templateList',
        'selectedTemplate'
    ],
    data() {
        return {
            // templateList: [],
            // selectedTemplate: -1,
        };
    },
    filters: {},
    computed: {},
    watch: {
        'templateList': {
            handler () {
                console.log(this.templateList,'templateList')
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.getTemplateList();
    },
    mounted() {},
    updated() {},
    beforeDestroy() {},
    methods: {
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        async getTemplateList() {
            return;
            console.log("[获取模板列表]this.type", this.type);
            this._fet("/school/schoolArchivesType/listByCondition", {
                schoolId: this.schoolId,
                archivesPersonType: this.type == "teacher" ? "2" : "1", // 档案应用人类型 1学生档案 2教师档案
            }).then((res) => {
                this.handleRes(res, async () => {
                    let templateList = res.data.data;
                    if (this.mode == "stu") {
                        // 学生端校验权限
                        let stuPermissions = await this._fet(
                            "/school/schoolStudent/getStuArchives",
                            {
                                schoolId: this.schoolId,
                                id: this.stuBasicInfo.id,
                            },
                        );
                        console.log(
                            "[获取学生权限]stuPermissions",
                            stuPermissions,
                        );
                        stuPermissions = stuPermissions.data;
                        if (stuPermissions.code == "200") {
                            stuPermissions = stuPermissions.data;
                            if (stuPermissions.flag === true) {
                                let stuTemplateList = stuPermissions.list;
                                templateList = templateList.filter(
                                    (e) =>
                                        stuTemplateList.findIndex(
                                            (i) => i.id == e.id,
                                        ) > -1,
                                );
                                templateList = templateList.map((e) => {
                                    e.mode = "edit";
                                    return e;
                                });
                            } else if (stuPermissions.flag === false) {
                                templateList = templateList.map((e) => {
                                    e.mode = "view";
                                    return e;
                                });
                            } else {
                                templateList = [];
                            }
                        } else {
                            this.$message.error(
                                "获取权限失败，请重试或联系管理员",
                            );
                            return;
                        }
                    } else {
                        templateList = templateList.map((e) => {
                            e.mode = this.mode;
                            return e;
                        });
                    }
                    this.templateList = templateList;
                    console.log(
                        "[获取档案模板列表]this.templateList:",
                        this.templateList,
                    );

                    if (this.templateList.length > 0) {
                        this.selectedTemplate = 0;
                    }
                    this.$emit("getTemplateList", this.templateList);
                });
            });
        },
        // 点击模板
        templateClick(item, index) {
            this.selectedTemplate = index;
            console.log("[点击档案模板]", item, index, this.selectedTemplate);
        },
    },
};
</script>

<style lang="scss" scoped>
.stu-info-fill {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.template-list {
    width: 180px;
    height: 100%;
    background: #fafbfc;
    border-radius: 2px 2px 0px 0px;
    margin: 16px 0px 16px 20px;

    .template-item {
        height: 48px;
        border-radius: 4px;
        box-sizing: border-box;
        text-align: center;
        line-height: 48px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        cursor: pointer;
    }

    .template-item-selected {
        background: #e6f0fa;
        border: 1px solid #3C7FFF;
        color: #3C7FFF;
    }
}

.form-div {
    width: 100%;
    height: 100%;
}
</style>
